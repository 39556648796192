/* test */
.navlinks  {
  position: unset !important;  
}

.carousel-container {
  width: 100%;
  /* -webkit-box-reflect: below 27px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4)); */
}

/* .carouselItem{
  max-width: 50px;
  min-width: 50px;
}  */

.custom-dot-list-style{
  margin-top: 2rem;
}

@media only screen and (max-width: 600px){
  .carousel-container {
    width: 100%;
    /* -webkit-box-reflect: below 27px linear-gradient(to bottom, rgba(0,0,0,0.0), rgba(0,0,0,0.4)); */
    height: 100vh;
  }
}

