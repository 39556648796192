
.registerPage{
    background-color: #000000;
    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.registerFormContainer
{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
}
/* test */
.registerForm
{    
    display: flex;
    flex-direction: column;
    gap: 2vh;
    align-items: center;
}

.registerForm input
{    
    border: none;
}

.registerForm input[type=file]::file-selector-button {
    border: none;
    background: linear-gradient(179.39deg, #2960C0 0.53%, #6CE6F9 234.01%);
    padding: 10px 20px;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    text-align: center;
  }

.registerForm input[type=file]:active {
    color: rgba(0, 0, 0, 0);
    display: none;
    
  }
  
.registerForm input[type=file] {
    color: rgba(0, 0, 0, 0);
    display: none;
    
  }
  
  input[type=file]::file-selector-button:hover {
    background: #0d45a5;
  }

  .formFile
  {
    font-family: 'Chakra Petch';
    font-size: 20px;
    border: none;
    background: linear-gradient(179.39deg, #2960C0 0.53%, #6CE6F9 234.01%);
    padding: 15px 20px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    text-align: center;
  }

  .formFileContainer
  {
    width: 100%;
    display: flex;
    justify-content: left;
    gap: 2vw;
  }

.registerFormInput{
    font-family: 'Chakra Petch';
    font-size: 20px;
    color: rgba(255, 255, 255, 0.5);
    text-align: left;
    height: 50px;
    background: #272727;
}

.registerFormInput:focus
{
    color: rgba(255, 255, 255, 0.5);
    background: #272727;
}

.registerSubmitButton{
    cursor: pointer;
    font-family: 'Chakra Petch';
    font-size: 20px;
    border: none;
    margin-top: 0px;
    background: linear-gradient(179.39deg, #2960C0 0.53%, #6CE6F9 234.01%);
    padding: 15px 80px;
    color: #fff;
    cursor: pointer;
    transition: background .2s ease-in-out;
    text-align: center;
}

.head1{
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
    font-family: 'Chakra Petch';
    font-style: normal;
    font-weight: 700;
    font-size: 80px;
    line-height: 122px;
    color: #FFFFFF;
}

.registerImage{
    width: 35%;
    height: 100%;
    background: url('../images/Rectangle.png');
    background-repeat: no-repeat;
    background-size: 95% 100%; 
}

.headingContainer
{
    display: flex;
    justify-content: left;
    align-items: left;
    width: 100%;
}

.uploaded
{
    display: flex;
    align-items: center;
    font-family: 'Chakra Petch';
    color: greenyellow
}

.uploaded::before
{
    content: 'Image Selected';
}

.notUploaded
{
    display: flex;
    align-items: center;
    font-family: 'Chakra Petch';
    color: red;
}

.notUploaded::before
{
    content: 'Please Select an Image';
}

.filepond--drop-label.filepond--drop-label label {
    width: 10vw;
    background: linear-gradient(179.39deg, #2960C0 0.53%, #6CE6F9 234.01%);
    color: white;
    cursor: pointer;
}

.filepond--drop-label {
    display: unset!important;
}

.filepond--root {
    height: 8vh;
}

.filepond--list-scroller
{
    position: unset!important;
    height: 10vh!important;
}

.filepond--panel
{
    display: none;
}

.UploadNProgress {
    display: flex;
    gap: 10vw;
    width: 50vw;
    align-items: center;
    font-family: 'Chakra Petch';
    color: white;
}

.filepond--credits
{
    display: none!important;
}

.filepond--root {
    margin-bottom: 0px!important;
    height: 10vh;
}

.filepond--wrapper
{
    width: 50vw;
}

.Progress
{
    width: 50vw;
}

@media only screen and (max-width:600px){
    .registerPage{
        height:85vh;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .registerImage{
        display:none;
    }

    .registerFormContainer
    {
        width: 90%;
        justify-content: center;
    }

    .head1
    {
        font-size: 6vh;
        line-height: 8vh;
    }

    .upl-txt
    {
        font-size: 2vh;
    }

    .filepond--wrapper {
        width: 90vw;
    }

    .filepond--drop-label.filepond--drop-label label {
        width: 40vw;
    }

    .UploadNProgress {
        gap: 0vw;
        width: 90vw;
    }

    .Progress
    {
        width: 100vw!important ;
    }
}